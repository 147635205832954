<template lang="html">
    <MultiplePlaylists></MultiplePlaylists>
</template>

<script>
import MultiplePlaylists from '@/components/playlists/MultiplePlaylists.vue'
export default {
    components: {
        MultiplePlaylists
    },

}
</script>

<style lang="css">
</style>
