<template lang="html">
    <div v-if="playlists" class="component-wrapper">
        <div class="add-your-own-div d-flex">
            <div class="flex-row mb-3 create-own-playlist-row">
                <p id="help-build">
                    Want to help build this community?
                </p>
                <p>
                    <a id="create-playlist" href="/create-collection">Create your own collection!
                        <img fluid rounded :src="require(`@/assets/img/build.png`)" class="icon-with-text-right">
                    </a>
                </p>
            </div>
        </div>
        <div class="mb-2" v-for="(playlist, index) in playlists.results" :key="index">
            <a :href="`/collections/${playlist.id}`" class="single-podcast-link">
                <div class="single-playlist-link">
                    <div class="col-1 d-flex playlist-image-container">
                        <div class="playlist-image" :style="getPlaylistImageStyle(playlist)">
                            <p class="playlist-image-text" :style="getPlaylistImageFontStyle(playlist)">
                                {{getPlaylistInitials(playlist.name)}}
                            </p>
                        </div>
                    </div>
                    <div class="container-fluid">
                        <div class="row">
                            <p class="playlist-name col-11">
                                {{playlist.name}}
                            </p>
                        </div>
                        <div class="row d-md-flex d-lg-none">
                            <!-- <small class="multiple-playlists-summary col-11" v-if="playlist.summary.length > 30">
                                {{playlist.summary.slice(0, 30)}}...
                            </small> -->
                            <small class="multiple-playlists-summary col-11">
                                {{playlist.summary}}
                            </small>
                        </div>
                        <div class="row d-none d-lg-flex">
                            <!-- <small class="multiple-playlists-summary col-11" v-if="playlist.summary.length > 120">
                                {{playlist.summary.slice(0, 120)}}...
                            </small> -->
                            <small class="multiple-playlists-summary col-11">
                                {{playlist.summary}}
                            </small>
                        </div>
                    </div>
                </div>
            </a>
        </div>
        <div class="pagination-buttons d-flex">
            <div v-if="this.previous" class="previous-button pagination-button" @click="decrementSearchResults">
                <p>
                    Previous
                </p>
            </div>
            <div v-if="this.next" class="next-button pagination-button" @click="incrementSearchResults">
                <p>
                    Next
                </p>
            </div>

        </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            playlists: {},
            next: null,
            previous: null
        }
    },
    mounted() {
        this.getAllPlaylists()
    },
    methods: {
        getAllPlaylists() {
            this.$http.get(`${this.$store.state.endpoints.baseUrl}playlists/?limit=20&ordering=name`).then((response) => {
                this.next = response.data.next
                this.previous = response.data.previous
                this.playlists = response.data
            })
        },
        getPlaylistInitials(name) {
            name = name.split(' ')
            let wordsToRemove = ['a', 'the', 'of', 'in', 'at', 'but', 'by', 'for', 'to', 'and']
            let filteredName = name.filter(word =>
                                !wordsToRemove.includes(word)
                                && !wordsToRemove.includes(word.toUpperCase())
                                && !wordsToRemove.includes(word.toLowerCase())
                                && !wordsToRemove.includes(word.slice(0,1) + word.slice(1, word.length-1)))
            filteredName = filteredName.join(' ')
            return filteredName.split(' ').map((n)=>n[0].toUpperCase()).join('').slice(0,3)
        },
        getPlaylistImageBackgroundColor(playlist) {
            let colors = ['#3b3b3b', '#3c2222', '#222c3c', '#223c2b', '#3a223c']
            let summaryLength = playlist.summary.length
            let index = summaryLength % colors.length
            return colors[index]
        },
        getPlaylistImageFontColor(playlist) {
            let colors = ['#7afafe', '#fe7a7a', '#84fe7a','#fbfe7a', '#807afe']
            let nameLength = playlist.name.length
            let index = nameLength % colors.length
            return colors[index]
        },
        getPlaylistImageStyle(playlist) {
            return `background:${this.getPlaylistImageBackgroundColor(playlist)};
                    margin-left: auto;
                    height: 3em;
                    width: 3em;
                    position: absolute;
                    left: 0em;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    border-radius: 0.2em;`
        },
        getPlaylistImageFontStyle(playlist) {
            return `align-self: center;
                    font-size: 1.5em;
                    color: #84fe7a;
                    font-weight: bolder;
                    cursor: default;
                    color:${this.getPlaylistImageFontColor(playlist)};`
        },
        incrementSearchResults() {
            this.$http.get(this.next).then((response) => {
                this.next = response.data.next
                this.previous = response.data.previous
                this.playlists = response.data
                window.scrollTo(0, 0)
            })
        },
        decrementSearchResults() {
            this.$http.get(this.previous).then((response) => {
                this.next = response.data.next
                this.previous = response.data.previous
                this.playlists = response.data
                window.scrollTo(0, 0)
            })
        }
    },
}
</script>

<style lang="css" scoped>
.single-playlist-link {
    padding: 0.5em;
    display: flex;
    cursor: pointer;
    text-decoration: none;
    color: white;
    border-radius: 20px;
    box-shadow: var(--globalButtonShadow);
    max-height: 6em;
    min-height: 6em;
}
.single-playlist-link:hover {
    background: var(--hoverColor);
    color: white;
}
.single-podcast-link {
    text-decoration: none !important;
    margin-bottom: 1em;
}
#create-playlist {
    font-weight: bolder;
    color: white;
    text-decoration: underline;
    font-size: 1em;
}
.add-your-own-div {
    align-items: center;
    margin-right: 2vw;
}
.create-own-playlist-row {
    font-size: 1.5em;
}
.multiple-playlists-summary {
    align-self: center;
    word-wrap: break-word;
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.playlist-name {
    align-self: center;
    font-size: 1.2em;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.icon-with-text-right {
        height: 1em;
        width: auto;
        margin-left: 0.2em;
}
.playlist-image-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: 0.2em;
}
#help-build {
    font-size: 0.8em;
    color: white;
}
.next-button {
    margin-left: auto;
}
.previous-button {
    margin-right: auto;
}
.pagination-button:active {
    transform: translateY(4px);
}
.pagination-button:hover {
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    background: var(--hoverColor);
}
.pagination-button {
    background: var(--lightBackground);
    width: 5em;
    border-radius: 0.4em;
    padding: 0.4em;
    display: flex;
    justify-content: center;
    box-shadow: var(--globalButtonShadow);
    margin-bottom: 1em;
    cursor: pointer;
    color: white;
}
</style>
